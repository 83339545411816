import React, { useRef, useEffect, useState } from "react";
import { Html, PerspectiveCamera } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { gsap } from "gsap";
import { Sine } from "gsap";
import Content from "./contect";
import { useSelector } from 'react-redux';

// Linear interpolation function
function lerp(start, end, t) {
    return start + (end - start) * t;
}

export default function Fiber() {
    const [maxpos, setMaxpos] = useState(420);
    const [minpos, setMinpos] = useState(null);
    const [data, setData] = useState({});
    const newDataId = useSelector(state => state.auth.newDataId);
    const camera = useRef();
    const [cameraPosition, setCameraPosition] = useState(maxpos);
    const sliderRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/getById/${newDataId}`);
                if (!response.ok) throw new Error("Network response was not ok");
                const jsonData = await response.json();
                const positions = jsonData?.data?.Items.map(item => item?.POSITION);
                const newMinpos = Math.min(...positions) + 10;
                setMinpos(newMinpos);
                setData(jsonData?.data);
            } catch (error) {
                console.error("Error fetching data from the API:", error);
            }
        };

        fetchData();
    }, []);

    // Function to update camera and slider position
    const updatePositions = (newPosition) => {
        if (!camera.current) return;
        
        // Clamp the position
        const clampedPosition = Math.max(minpos, Math.min(maxpos, newPosition));
        
        // Update camera position with animation
        gsap.to(camera.current.position, {
            z: clampedPosition,
            duration: 1.2,
            ease: Sine.easeOut,
            onUpdate: () => {
                // Update both camera position state and slider value
                setCameraPosition(camera.current.position.z);
                if (sliderRef.current) {
                    sliderRef.current.value = (maxpos + minpos) - camera.current.position.z;
                }
            },
        });
    };

    useEffect(() => {
        if (!minpos) return;

        const handleWheel = (event) => {
            const t = (camera.current.position.z - minpos) / (maxpos - minpos);
            const maxZMovement = lerp(0.9, 0.03, Math.abs(t));
            const zMovement = event.deltaY * maxZMovement;
            const newPosition = camera.current.position.z + zMovement;
            updatePositions(newPosition);
        };

        const handleKeyDown = (event) => {
            const keyStep = 5;
            let zMovement = 0;

            if (event.key === 'ArrowUp' || event.key === 'PageUp') {
                zMovement = -keyStep;
            } else if (event.key === 'ArrowDown' || event.key === 'PageDown') {
                zMovement = keyStep;
            }

            if (zMovement !== 0) {
                const newPosition = camera.current.position.z + zMovement;
                updatePositions(newPosition);
            }
        };

        window.addEventListener("wheel", handleWheel);
        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("wheel", handleWheel);
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [minpos, maxpos]);

    useEffect(() => {
        const touchSpeedFactor = 0.9;

        const handleTouchStart = (event) => {
            const initialTouchY = event.touches[0].clientY;
            const initialCameraPosition = camera.current.position.z;

            const handleTouchMove = (moveEvent) => {
                const deltaY = moveEvent.touches[0].clientY - initialTouchY;
                const newPosition = initialCameraPosition - deltaY * touchSpeedFactor;
                updatePositions(newPosition);
            };

            const handleTouchEnd = () => {
                window.removeEventListener("touchmove", handleTouchMove);
                window.removeEventListener("touchend", handleTouchEnd);
            };

            window.addEventListener("touchmove", handleTouchMove);
            window.addEventListener("touchend", handleTouchEnd);
        };

        window.addEventListener("touchstart", handleTouchStart);

        return () => {
            window.removeEventListener("touchstart", handleTouchStart);
        };
    }, [minpos, maxpos]);

    const handleSliderChange = (sliderValue) => {
        const newPosition = (maxpos + minpos) - parseFloat(sliderValue);
        updatePositions(newPosition);
    };

    return (
        <>
            <Canvas>
                <PerspectiveCamera makeDefault position={[0, 0, maxpos]} ref={camera} />
                <Content updateCameraPosition={updatePositions} />
            </Canvas>

            <div className="slider-container" style={{ background: 'transparent', boxShadow: 'none', border: 'none', zIndex: '99999999' }}>
                <div style={{ textAlign: "center" }}>
                    <img src="/watermark.jpg" style={{ width: "400px" }} alt="watermark" />
                </div>
                <input
                    ref={sliderRef}
                    type="range"
                    className="slider"
                    min={minpos}
                    max={maxpos}
                    step={5}
                    value={(maxpos + minpos) - cameraPosition}
                    onTouchStart={(e) => {
                        e.stopPropagation();
                    }}
                    onChange={(e) => handleSliderChange(e.target.value)}
                />
            </div>
        </>
    );
}



// import React, { useRef, useEffect, useState } from "react";
// import { PerspectiveCamera } from "@react-three/drei";
// import { Canvas, useFrame } from "@react-three/fiber";
// import { Sine, Power2, Power3,gsap } from "gsap";
// import Content from "./contect";
// import { useSelector } from "react-redux";
// import { Frustum, Matrix4, Vector3 } from "three";

// function lerp(start, end, t) {
//     return start + (end - start) * t;
// }

// export default function Fiber() {
//     const [maxpos, setMaxpos] = useState(420);
//     const [minpos, setMinpos] = useState(125);
//     const [ringPositions, setRingPositions] = useState([413, 393, 373]);
//     const [cameraPosition, setCameraPosition] = useState(maxpos);
//     const [isSliderMoving, setIsSliderMoving] = useState(false);

//     const newDataId = useSelector(state => state.auth.newDataId);
//     const camera = useRef();

//     // For visibility calculations
//     const frustum = useRef(new Frustum());
//     const cameraViewMatrix = useRef(new Matrix4());

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const response = await fetch(`${process.env.REACT_APP_API_URL}/getById/${newDataId}`);
//                 if (!response.ok) throw new Error("Network response was not ok");
//                 const jsonData = await response.json();

//                 const positions = jsonData?.data?.Items.map(item => item?.POSITION) || [];
//                 setRingPositions([...ringPositions, ...positions]);
//                 setMinpos(positions.length > 0 ? Math.min(...positions) + 10 : 125);
//             } catch (error) {
//                 console.error("Error fetching data from the API:", error);
//             }
//         };

//         fetchData();
//     }, [newDataId]);

//     const areRingsVisible = () => {
//         if (!camera.current) return true;

//         cameraViewMatrix.current.multiplyMatrices(
//             camera.current.projectionMatrix,
//             camera.current.matrixWorldInverse
//         );
//         frustum.current.setFromProjectionMatrix(cameraViewMatrix.current);

//         return ringPositions.some(ringPos => {
//             const ringVector = new Vector3(0, 0, ringPos);
//             return frustum.current.containsPoint(ringVector);
//         });
//     };

//     const getDynamicStepSize = () => {
//         // Increased base step sizes for both conditions
//         return areRingsVisible() ? 4 : 25;
//     };

//     const updateCameraPosition = (newPosition, immediate = false) => {
//         if (!camera.current) return;

//         const boundedPosition = Math.max(minpos, Math.min(maxpos, newPosition));

//         if (immediate || isSliderMoving) {
//             camera.current.position.z = boundedPosition;
//             setCameraPosition(boundedPosition);
//         } else {
//             gsap.to(camera.current.position, {
//                 z: boundedPosition,
//                 duration: 1.7,
//                 ease: Power3.easeOut,
//                 onUpdate: () => setCameraPosition(camera.current.position.z),
//             });
//         }
//     };

//     useEffect(() => {
//         const handleInput = (event) => {
//             if (!camera.current || isSliderMoving) return;

//             let zMovement = 0;
//             if (event.deltaY) {
//                 // Increased wheel scroll speed
//                 zMovement = event.deltaY * 0.3;  
//                 if (!areRingsVisible()) {
//                     zMovement *= 3;
//                 }
//             }

//             const newCameraPosition = camera.current.position.z + zMovement;
//             updateCameraPosition(newCameraPosition);
//         };

//         // Add keyboard event handler
//         const handleKeyboard = (event) => {
//             if (!camera.current) return;

//             let zMovement = 0;
//             const baseStepSize = getDynamicStepSize();
//             // Increased keyboard multiplier
//             const keyboardMultiplier = areRingsVisible() ? 3 : 5;  // Different multipliers for visible/non-visible areas
//             const stepSize = baseStepSize * keyboardMultiplier;

//             switch (event.key) {
//                 case 'ArrowRight':
//                     zMovement = -stepSize;
//                     break;
//                 case 'ArrowLeft':
//                     zMovement = stepSize;
//                     break;
//                 default:
//                     return;
//             }

//             const newCameraPosition = camera.current.position.z + zMovement;
//             updateCameraPosition(newCameraPosition);
//             event.preventDefault();
//         };

//         window.addEventListener("wheel", handleInput);
//         window.addEventListener("keydown", handleKeyboard);

//         return () => {
//             window.removeEventListener("wheel", handleInput);
//             window.removeEventListener("keydown", handleKeyboard);
//         };
//     }, [minpos, maxpos, ringPositions, isSliderMoving]);

//     const handleSliderChange = (event) => {
//         const newPosition = (maxpos + minpos) - parseFloat(event.target.value);
//         updateCameraPosition(newPosition, true);
//     };

//     return (
//         <>
//             <Canvas>
//                 <PerspectiveCamera makeDefault position={[0, 0, maxpos]} ref={camera} />
//                 <Content updateCameraPosition={updateCameraPosition} />
//             </Canvas>

//             <div className="slider-container" style={{ background: 'transparent', boxShadow: 'none', border: 'none', zIndex: '99999999' }}>
//                 <div style={{ textAlign: "center" }}>
//                     <img src="/watermark.jpg" style={{ width: "400px" }} />
//                 </div>
//                 <input
//                     type="range"
//                     className="slider"
//                     min={minpos}
//                     max={maxpos}
//                     step={getDynamicStepSize()}  // Dynamic step size based on visibility
//                     value={(maxpos + minpos) - cameraPosition}
//                     onTouchStart={(e) => { e.stopPropagation(); setIsSliderMoving(true); }}
//                     onTouchEnd={() => setIsSliderMoving(false)}
//                     onMouseDown={() => setIsSliderMoving(true)}
//                     onMouseUp={() => setIsSliderMoving(false)}
//                     onChange={handleSliderChange}
//                 />
//             </div>
//         </>
//     );
// };

